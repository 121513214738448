@import url("https://use.typekit.net/ixd7fuk.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

body, html {
  overflow-x: hidden;
  position: relative;
  background-color: #E2DEDD;
}

h1 {
  font-size: clamp(36px, 6vw, 100px);
  font-weight: 700;
  letter-spacing: 0.105em;
  text-align: center;
  color: #575757;
}

h2 {
  font-size: clamp(40px, 3.5vw, 90px);
  font-weight: 400;
  line-height: clamp(60px, 6vw, 72px);
  letter-spacing: 0.05em;
  text-align: center;
  color: #575757;
}

h3 {
  font-size: clamp(24px, 3vw, 40px);
  font-weight: 600;
  line-height: clamp(60px, 6vw, 72px);
  letter-spacing: 0em;
  color: #575757;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1.785em;
  text-align: center;
  color: white;
  z-index: 10;
  position: relative;
}

.h4-bold {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  color: #575757;
}

.h4-spaced-dark {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1.785em;
  color: #575757;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.04em;
}

p {
  font-size: 16px;
  color: #575757;
  margin-bottom: 0px;
}

.p-normal {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: #575757;
}

.p-gray {
  color: #9E9E9E;
}

.p-large {
  font-size: clamp(14px, 1.6vw, 24px);
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: #575757;
}

.p-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.04em;
}

li {
  font-size: 16px;
  font-weight: 400;
  line-height: clamp(24px, 2.1vw, 36px);
  letter-spacing: 0.04em;
  text-align: left;
  color: #575757;
}

ul {
  list-style: none;
  padding-left: 0px;
}

.height-screen {
  height: 100vh;
}

.bg-text-large {
  font-size: 300px;
  font-weight: 500;
  line-height: 450px;
  letter-spacing: 0.105em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  color: white;
}

.color-splash-1 {
  background-color: #D1E7E8;
  width: 400px;
  height: 300px;
  position: absolute;
  border-radius: 50%;
  filter: blur(40px);
  top: 50%;
  left: 50%;
  transform: translate(10%, -50%);
  opacity: 80%;
}
.color-splash-2 {
  background-color: #F6DEC8;
  width: 400px;
  height: 300px;
  position: absolute;
  border-radius: 50%;
  filter: blur(40px);
  top: 50%;
  left: 50%;
  transform: translate(30%, -70%);
  opacity: 60%;
}

.btn-primary {
  border-radius: 50px;
  background-color: #E2DEDD;
  font-weight: 700;
  font-size: clamp(15px, 1.3vw, 20px);
  padding: 0.5rem 4rem;
  border: none;
  box-shadow: 6px 6px 12px rgba(87, 87, 87, 0.4), inset 6px 6px 12px rgba(255, 255, 255, 0.4);
  position: relative;
  z-index: 10;
  color: #575757;
  transition: all 0.4s ease;
}
.btn-primary:hover {
  transform: translate(-1px, -1px);
  background-color: #f1f1f1;
  box-shadow: 8px 8px 16px rgba(87, 87, 87, 0.4), inset 6px 6px 12px rgba(255, 255, 255, 0.4);
}

.btn-secondary {
  border-radius: 50px;
  background-color: #E2DEDD;
  font-weight: 700;
  font-size: 14px;
  padding: 0.2rem 2rem;
  border: none;
  box-shadow: 4px 4px 4px rgba(87, 87, 87, 0.2), inset 6px 6px 12px rgba(255, 255, 255, 0.4);
  position: relative;
  z-index: 10;
  color: #575757;
}

.gradient-vertical {
  background-image: linear-gradient(to bottom, #F5F5F5, rgba(246, 222, 200, 0.8), rgba(209, 231, 232, 0.8), #F5F5F5);
  padding: clamp(1rem, 6.4vw, 6rem);
}

.bg-dark {
  background-color: blue;
}

.top-space {
  margin-top: clamp(1rem, 6.4vw, 6rem);
}

.xy-padding {
  padding: clamp(1rem, 6.4vw, 6rem);
}

.x-padding {
  padding-left: clamp(1rem, 6.4vw, 6rem);
  padding-right: clamp(1rem, 6.4vw, 6rem);
}

.y-padding {
  padding-top: clamp(1rem, 6.4vw, 6rem);
  padding-bottom: clamp(1rem, 6.4vw, 6rem);
}

.xy-padding-adjusted {
  padding: clamp(1rem, 6vw, 5rem);
}

.link:hover {
  cursor: pointer;
}

.clock-bg {
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-image: linear-gradient(#575757, rgba(87, 87, 87, 0)), url("images/ClockedInBG.jpg");
}

.paw-portraits-bg {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-position: center;
  background-image: linear-gradient(white, rgba(255, 255, 255, 0.2)), url("images/PawPortraitsBG.jpg");
  transition: all 800ms ease;
}

.farah-bg {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-position: bottom;
  background-image: linear-gradient(white, rgba(255, 255, 255, 0.2)), url("images/Architecture/Architecture.png");
  transition: all 2000ms ease;
}

.featured-project-text-container {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  padding: clamp(1rem, 6.4vw, 6rem);
  width: clamp(320px, 50%, 680px);
}

.menu-items {
  z-index: 2;
}

.menu-items:hover > .menu-item {
  opacity: 0.3;
  transition: all 0.4s;
}

.menu-items:hover > .menu-item:hover {
  opacity: 1;
}

.menu-background-pattern {
  height: 100vh;
  width: 100vw;
  background-image: radial-gradient(rgba(255, 255, 255, 0.1) 10%, transparent 9%);
  background-position: 0% 0%;
  background-size: 40vmin 40vmin;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  transition: opacity 800ms ease, background-size 800ms ease, background-position 800ms ease;
}

.menu-items:hover ~ .menu-background-pattern {
  background-size: 35vmin 35vmin;
  opacity: 0.5;
}

.card {
  border: none;
  border-radius: 12px;
  height: 50vh;
  overflow: hidden;
  position: relative;
  transition: all 800ms ease;
  backface-visibility: hidden;
}
.card__color-1 {
  background-color: #9E9E9E;
}
.card__color-2 {
  background-color: #F5F5F5;
  box-shadow: 4px 4px 8px rgba(87, 87, 87, 0.4);
}
.card .card-image-1 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), white), url("images/FlecksPreview.png");
}
.card .card-image-2 {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), white), url("images/basketball.jpg");
}
.card .card-image-3 {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), white), url("images/tackticalballoonsthumbnail.png");
}
.card .card-image-4 {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), white), url("images/dog.png");
}
.card .card-text {
  position: absolute;
  bottom: 0;
  color: #575757;
  width: 100%;
}
.card:hover {
  transform: scale(1.02);
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .farah-bg {
    background-size: fill;
    background-position: top;
    background-repeat: repeat-y;
  }
}

